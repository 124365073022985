.cardWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'image info'
    'buttons buttons';
}

.imageArea {
  grid-area: image;
}

.infoArea {
  grid-area: info;
}

.buttonsArea {
  grid-area: buttons;
}

@media (min-width: 640px) {
  .cardWrapper {
    grid-template-areas: 'image info';
  }
}
